import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import {
  Box,
  Container,
  Button,
  Card,
  CardHeader,
  Divider,
  CardContent,
  CircularProgress,
  TextField,
  Alert,
  Grid
} from '@material-ui/core';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import { useParams, useNavigate } from 'react-router-dom';
import { useGet, usePost, usePut } from '../../API/request';
import { BallTriangle } from 'react-loader-spinner';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import '../../styles/Avatar/style.css';

const AdministrationEdit = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const getOne = useGet();

  const postPhoto = usePost();
  const putStaff = usePut();
  const [isLoaded, setIsLoaded] = useState(true);
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const [nothingChanged, setNothingChanged] = useState(true);

  const [uploadedImg, setUploadedImg] = useState('/static/images/defphoto.jpg');
  const [isValidateEmail, setIsValidateEmail] = useState(true);
  const [values, setValues] = useState({
    name: '',
    photo: uploadedImg,
    email: '',
    title: ''
  });
  const [errors, setErrors] = useState({
    name: false,
    photo: false,
    email: false,
    title: false
  });

  const [alert, setAlert] = useState({
    txt: '',
    isVisible: false,
    type: 'error'
  });

  const handleChange = (event) => {
    setNothingChanged(false);

    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
    setErrors({
      ...errors,
      [event.target.name]: false
    });
  };

  const avaUploaded = (event) => {
    setNothingChanged(false);
    setUploadedImg(URL.createObjectURL(event.target.files[0]));
    setValues({
      ...values,
      photo: event.target.files[0]
    });
  };

  const handleChangeEmail = (event) => {
    const reg =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    setIsValidateEmail(!!event.target.value.match(reg));
    setValues({
      ...values,
      email: event.target.value
    });
  };
  const showAlert = (type, text) => {
    setAlert({
      txt: text,
      type,
      isVisible: true
    });

    setTimeout(() => {
      setAlert({
        txt: text,
        type,
        isVisible: false
      });

      setSubmitDisabled(false);
    }, 2500);
  };

  const validateInfo = () => {
    let validComplete = true;
    let formErrors = { ...errors };

    if (values.name === '') {
      validComplete = false;
      formErrors.name = false;
      showAlert('error', 'Название не должно быть пустым');
    }

    if (values.title === '') {
      validComplete = false;
      formErrors.title = false;
      showAlert('error', 'Поле Должность не должно быть пустым');
    }

    if (values.email === '') {
      validComplete = false;
      formErrors.email = false;
      showAlert('error', 'Поле email не должно быть пустым');
    } else if (!isValidateEmail) {
      validComplete = false;
      formErrors.email = false;
      showAlert('error', 'Вы передели в поле email не корректные данные');
    }

    setErrors(formErrors);
    return validComplete;
  };

  const uploadPhoto = async () => {
    if (nothingChanged) {
      showAlert('error', 'Нет изменений');
      return;
    }

    setSubmitDisabled(true);

    let data = new FormData();
    data.append('photo', values.photo);
    data.append('fields', values);


    postPhoto(`administration/${id}/photo`, data)
      .then((resp) => {
        if (resp.status === 'success') {
          showAlert('success', 'Данные успешно обновленны');
        } else {
          showAlert('error', 'Ошибка');
        }
      })
      .catch((err) => {
        showAlert('error', 'Ошибка сервера');
      })
      .finally(() => {
        setSubmitDisabled(false);
      });
  };

  const submitInfo = async () => {
    if (nothingChanged) {
      showAlert('error', 'Нет изменений');
      return;
    }

    if (validateInfo()) {
      setSubmitDisabled(true);

      const data = {
        name: values.name,
        email: values.email,
        title: values.title,
        photo: values.photo
      };

      putStaff(`administration/${id}`, data)
        .then((resp) => {
          if (resp.status === 'success') {
            showAlert('success', 'Данные успешно обновленны');
          } else {
            showAlert('error', 'Ошибка');
          }
        })
        .catch((err) => {
          showAlert('error', 'Ошибка сервера');
        })
        .finally(() => {
          setSubmitDisabled(false);
        });
    }
  };

  useEffect(() => {
    getOne(`administration/${id}`)
      .then((resp) => {
        if (resp.status === 'success') {
          const data = {
            photo: resp.data.member.photo,
            name: resp.data.member.name,
            title: resp.data.member.title,
            email: resp.data.member.email
          };

          setValues(data);
          const photo = resp.data.member.photo
            ? `${process.env.REACT_APP_API_URL}/uploads/logos/${resp.data.member.photo}`
            : '';

          setUploadedImg(photo);
        }
      })
      .catch(() => {
        showAlert(
          'error',
          'Произошла ошибка при загрузке, попробуйте перезайти'
        );
      })
      .finally(() => {});

    setIsLoaded(true);
  }, []);

  if (!isLoaded) {
    return (
      <div className="loader">
        <BallTriangle
          height="100"
          width="100"
          color="grey"
          ariaLabel="loading"
        />
      </div>
    );
  }

  return (
    <>
      <Helmet>
        <title>Редактировать</title>
      </Helmet>
      <Box className="headerWrapper">
        <Box className="headerTitle">
          <Button startIcon={<ChevronLeft />} onClick={() => navigate(-1)}>
            Назад
          </Button>
          Администрация
        </Box>
        <ul className="headerList">
          <li onClick={() => navigate(-1)}>Администрация</li>
          <li>/</li>
          <li>Редактирование</li>
        </ul>
      </Box>

      {/*image*/}
      <Box sx={{ backgroundColor: 'background.default', pt: 3 }}>
        <Container maxWidth={false}>
          <Box sx={{ mb: 1 }}>
            <Alert
              severity={alert.type}
              style={{ display: alert.isVisible ? 'flex' : 'none' }}
            >
              {alert.txt}
            </Alert>
          </Box>
          <Box
            sx={{
              paddingBottom: 1
            }}
          >
            <form>
              <Card>
                <CardHeader title="Редактирование логотипа" />
                <Divider />
                <CardContent sx={{ position: 'relative' }}>
                  <div className="itemWrapper">
                    <div className="container">
                      <input
                        accept=".png, .jpg, .svg, .bmp, .tga, .webp"
                        type="file"
                        style={{ display: 'none' }}
                        id={1}
                        onChange={(event) => avaUploaded(event, 1)}
                      />
                      <label htmlFor={1}>
                        <img src={uploadedImg} className="itemImg" />
                        <div className="middle" />
                      </label>
                    </div>
                    <div className="help-text">
                      Доступны следующие расширения: .png .jpg .svg .bmp .tga
                      .webp
                    </div>
                  </div>
                </CardContent>
                <Divider />
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', p: 2 }}>
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={uploadPhoto}
                    disabled={submitDisabled}
                  >
                    Сохранить
                  </Button>
                </Box>
              </Card>
            </form>
          </Box>
        </Container>

        {/*info*/}
        <Card>
          <CardHeader title="Добавление нового сервиса услуг" />
          <Divider />
          <CardContent sx={{ position: 'relative' }}>
            <TextField
              fullWidth
              label="Имя"
              margin="normal"
              name="name"
              onChange={handleChange}
              type="text"
              value={values.name}
              variant="outlined"
              error={errors.name}
            />
            <TextField
              fullWidth
              label="Должность"
              margin="normal"
              name="title"
              onChange={handleChange}
              type="text"
              value={values.title}
              variant="outlined"
              error={errors.title}
            />
            <TextField
              fullWidth
              label="Email"
              margin="normal"
              name="email"
              onChange={handleChangeEmail}
              type="text"
              value={values.email}
              variant="outlined"
              error={errors.email}
            />

            <Alert
              severity={alert.type}
              style={{ display: alert.isVisible ? 'flex' : 'none' }}
            >
              {alert.txt}
            </Alert>
          </CardContent>
          <Divider />
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', p: 2 }}>
            <Button
              disabled={submitDisabled}
              color="primary"
              variant="contained"
              onClick={submitInfo}
            >
              Добавить
            </Button>
          </Box>
        </Card>
      </Box>
    </>
  );
};

export default AdministrationEdit;
