import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Link, useSearchParams } from 'react-router-dom';
import {
  Box,
  Container,
  Avatar,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button,
  TableFooter,
  TablePagination,
  TextField,
  Divider,
  Select,
  InputLabel,
  MenuItem,
  FormControl
} from '@material-ui/core';
import PerfectScrollbar from 'react-perfect-scrollbar';
import UserListSkelet from '../../skeletons/UserListSkelet';
import { useDelete, useGet } from '../../API/request';
import { useConfirm } from '../../components/Confirm/index';
import { BallTriangle } from 'react-loader-spinner';
import '../../styles/All.css';

const AdministrationList = () => {
  const confirm = useConfirm();
  const getAdministration = useGet();

  const deleteAdministration = useDelete();
  const [searchParams, setSearchParams] = useSearchParams();
  const [isLoaded, setIsLoaded] = useState(true);
  const [isDataLoading, setIsDataLoading] = useState(true);
  const [administration, setAdministration] = useState([]);

  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [count, setCount] = useState(0);

  const [queryParams, setQueryParams] = useState({
    name: searchParams.get('name') || '',
    title: searchParams.get('title') || '',
    id: searchParams.get('id') || ''
  });

  const handleChangeQueryParams = (event) => {
    setQueryParams({
      ...queryParams,
      [event.target.name]: event.target.value
    });
  };

  const handleFilterQueryParams = () => {
    let params = {};
    if (queryParams.name) {
      params.name = queryParams.name;
    }
    if (queryParams.id) {
      params.id = queryParams.id;
    }
    if (queryParams.title) {
      params.title = queryParams.title;
    }

    if (Object.keys(params).length !== 0) {
      setSearchParams(params);
    }
  };

  const loadservice = () => {
    setIsDataLoading(true);
    setIsLoaded(true);

    let endpoint = `administration?page=${page + 1}&limit=${limit}`;
    if (queryParams.name) {
      endpoint += `&name=${searchParams.get('name')}`;
    }

    if (queryParams.id) {
      endpoint += `&id=${searchParams.get('id')}`;
    }

    if (queryParams.title) {
      endpoint += `&title=${searchParams.get('title')}`;
    }
    getAdministration(endpoint)
      .then((resp) => {
        if (resp.status === 'success') {
          setAdministration(resp.data.administration.data);
          setCount(resp.data.currentCount || 0);
        }
        setIsDataLoading(false);
      })
      .catch((err) => {
        console.log(err.response);
        setAdministration([]);
        setCount(0);
        setIsDataLoading(false);
      })
      .finally(() => {
        setIsLoaded(false);
      });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeLimit = (event) => {
    setLimit(event.target.value);
    setPage(0);
  };

  const onDelete = (id) => {
    confirm({
      title: 'Администрация',
      content: 'Вы уверены, что хотите удалить?',
      onConfirm: () => {
        deleteAdministration(`administration/${id}`)
          .then((resp) => {
            if (resp.status === 'success') {
              loadservice();
            }
          })
          .catch((e) => {
            console.log(e.response);
          });
      }
    });
  };

  useEffect(() => {
    if (!queryParams.name) {
      searchParams.delete('name');
      setSearchParams(searchParams);
    }
    if (!queryParams.id) {
      searchParams.delete('id');
      setSearchParams(searchParams);
    }
    if (!queryParams.title) {
      searchParams.delete('title');
      setSearchParams(searchParams);
    }
  }, [queryParams]);

  useEffect(() => {
    loadservice();
  }, [page, limit, searchParams]);

  if (isLoaded) {
    return (
      <div className="loader">
        <BallTriangle
          height="100"
          width="100"
          color="grey"
          ariaLabel="loading"
        />
      </div>
    );
  }

  return (
    <>
      <Helmet>
        <title>Администрация</title>
      </Helmet>
      <Box className="headerWrapper">
        <Box className="headerTitle">Администрация</Box>
      </Box>
      <Box
        sx={{ backgroundColor: 'background.default', minHeight: '100%', py: 3 }}
      >
        <Container maxWidth={false}>
          {isDataLoading ? (
            <UserListSkelet />
          ) : (
            <>
              <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Box sx={{ marginLeft: 2 }}>
                  <Link to="/app/administration/add">
                    <Button color="primary" variant="contained">
                      Добавить
                    </Button>
                  </Link>
                </Box>
              </Box>
              <Box sx={{ pt: 3 }}>
                <Card>
                  <PerfectScrollbar>
                    <Box sx={{ minWidth: 1400 }}>
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          mx: 2,
                          mb: 1
                        }}
                      >
                        <TextField
                          fullWidth
                          label="Имя"
                          margin="normal"
                          name="name"
                          onChange={handleChangeQueryParams}
                          type="text"
                          value={queryParams.name}
                          variant="outlined"
                          style={{ width: '30%' }}
                        />
                        <TextField
                          fullWidth
                          label="Id"
                          margin="normal"
                          name="id"
                          onChange={handleChangeQueryParams}
                          type="text"
                          value={queryParams.id}
                          variant="outlined"
                          style={{ width: '30%' }}
                        />
                        <TextField
                          fullWidth
                          label="Должность"
                          margin="normal"
                          name="title"
                          onChange={handleChangeQueryParams}
                          type="text"
                          value={queryParams.title}
                          variant="outlined"
                          style={{ width: '30%' }}
                        />

                        <Button
                          color="primary"
                          variant="contained"
                          onClick={handleFilterQueryParams}
                          sx={{ mt: 2, mb: 1 }}
                        >
                          Применить
                        </Button>
                      </Box>
                      <Divider />
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell sx={{ width: 80 }}>Id</TableCell>
                            <TableCell>Фото</TableCell>
                            <TableCell>Имя</TableCell>
                            <TableCell>Должность</TableCell>
                            <TableCell>E-mail</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {administration?.map((admin) => (
                            <TableRow hover key={admin.id}>
                              <TableCell sx={{ width: 80 }}>
                                {admin?.id}
                              </TableCell>
                              <TableCell>
                                <Box
                                  sx={{ alignItems: 'center', display: 'flex' }}
                                >
                                  <Avatar
                                    src={
                                      admin.photo?.startsWith('http')
                                        ? admin.photo
                                        : `${process.env.REACT_APP_API_URL}/uploads/logos/${admin.photo}`
                                    }
                                  />
                                </Box>
                              </TableCell>

                              <TableCell>{admin?.name || '---'}</TableCell>
                              <TableCell>{admin?.title || '---'}</TableCell>
                              <TableCell>{admin?.email || '---'}</TableCell>

                              <TableCell>
                                <Box sx={{ display: 'flex' }}>
                                  <Box sx={{ display: 'flex' }}>
                                    <Link
                                      to={`/app/administration/${admin.id}`}
                                    >
                                      <Button
                                        color="primary"
                                        variant="contained"
                                      >
                                        Инфо.
                                      </Button>
                                    </Link>
                                    <Box sx={{ ml: 2 }}>
                                      <Link
                                        to={`/app/administration/edit/${admin.id}`}
                                      >
                                        <Button
                                          color="primary"
                                          variant="contained"
                                        >
                                          Редакт.
                                        </Button>
                                      </Link>
                                      <Button
                                        sx={{ ml: 2 }}
                                        color="primary"
                                        variant="contained"
                                        onClick={() => onDelete(admin?.id)}
                                      >
                                        Удалить
                                      </Button>
                                    </Box>
                                  </Box>
                                </Box>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                        <TableFooter>
                          <TableRow>
                            <TablePagination
                              labelRowsPerPage={
                                <span>Кол-во строк на странице:</span>
                              }
                              rowsPerPageOptions={[10, 20, 30, 40, 50]}
                              colSpan={7}
                              count={count}
                              rowsPerPage={limit}
                              page={page}
                              onPageChange={handleChangePage}
                              onRowsPerPageChange={handleChangeLimit}
                            />
                          </TableRow>
                        </TableFooter>
                      </Table>
                    </Box>
                  </PerfectScrollbar>
                </Card>
              </Box>
            </>
          )}
        </Container>
      </Box>
    </>
  );
};

export default AdministrationList;
