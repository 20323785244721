import React, { useState, useEffect } from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { usePost } from '../../../API/request';

function NewsEditor({ setValues, values }) {
  const postPhoto = usePost();
  const [editorLoaded, setEditorLoaded] = useState(false);
  useEffect(() => {}, []);

  const handleEditorReady = (editor) => {
    setEditorLoaded(true);

    editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
      return {
        upload: async () => {
          return new Promise(async (resolve, reject) => {
            const formData = new FormData();
            const file = await loader.file;

            formData.append('photo', file);

            try {
              const response = await postPhoto(
                'news-and-offers/news-photos',
                formData
              );
              if (response.status === 'success') {
                resolve({
                  default: `${process.env.REACT_APP_API_URL}/uploads/logos/${response.data.file}`
                });
              } else {
                reject(response.error);
              }
            } catch (error) {
              reject(error.message);
            }
          });
        }
      };
    };
  };

  const handleChange = (event, editor) => {
    const data = editor.getData();
    setValues({ ...values, description: data });
  };
  return (
   
      <CKEditor
        editor={ClassicEditor}
        data={values?.description}
        onReady={(editor) => {
          handleEditorReady(editor);
        }}
        onChange={(event, editor) => {
          handleChange(event, editor);
        }}
     
      />
    
  );
}

export default NewsEditor;
