import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Link, useSearchParams } from 'react-router-dom';
import {
  Box,
  Container,
  Avatar,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button,
  TableFooter,
  TablePagination,
  TextField,
  Divider,
  Select,
  InputLabel,
  MenuItem,
  FormControl
} from '@material-ui/core';
import PerfectScrollbar from 'react-perfect-scrollbar';
import UserListSkelet from '../../skeletons/UserListSkelet';
import { useDelete, useGet } from '../../API/request';
import { useConfirm } from '../../components/Confirm/index';
import { BallTriangle } from 'react-loader-spinner';
import '../../styles/All.css';

const NewsAndOffersList = () => {
  const confirm = useConfirm();
  const getNewsAndOffers = useGet();
  const getCategories = useGet();
  const deleteNewsAndOffers = useDelete();
  const [searchParams, setSearchParams] = useSearchParams();

  const [isLoaded, setIsLoaded] = useState(true);
  const [isDataLoading, setIsDataLoading] = useState(true);
  const [newsAndOffers, setNewsAndOffers] = useState([]);
  const [categories, setCategories] = useState([]);
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [count, setCount] = useState(0);

  const [queryParams, setQueryParams] = useState({
    title: searchParams.get('title') || '',
    news_and_offers_category_id: searchParams.get('category_id') || '',
    id: searchParams.get('id') || ''
  });

  const handleChangeQueryParams = (event) => {
    setQueryParams({
      ...queryParams,
      [event.target.name]: event.target.value
    });
  };

  const handleFilterQueryParams = () => {
    let params = {};
    if (queryParams.title) {
      params.title = queryParams.title;
    }
    if (queryParams.id) {
      params.id = queryParams.id;
    }
    if (queryParams.category_id) {
      params.news_and_offers_category_id = queryParams.category_id;
    }

    if (Object.keys(params).length !== 0) {
      setSearchParams(params);
    }
  };

  const loadNews = () => {
    getCategories('categories/news-and-offers').then((resp) => {
      if (resp.status === 'success') {
        setCategories(resp.data.categories.data);
      }
    });
    setIsDataLoading(true);
    setIsLoaded(true);

    let endpoint = `news-and-offers?page=${page + 1}&limit=${limit}`;
    if (queryParams.title) {
      endpoint += `&title=${searchParams.get('title')}`;
    }
    if (queryParams.category_id) {
      endpoint += `&news_and_offers_category_id=${searchParams.get(
        'news_and_offers_category_id'
      )}`;
    }
    if (queryParams.id) {
      endpoint += `&id=${searchParams.get('id')}`;
    }

    getNewsAndOffers(endpoint)
      .then((resp) => {
        if (resp.status === 'success') {
          setNewsAndOffers(resp.data.newsAndOffers.data);
          setCount(resp.data.currentCount || 0);
        }
        setIsDataLoading(false);
      })
      .catch((err) => {
        console.log(err.response);
        setNewsAndOffers([]);
        setCount(0);
        setIsDataLoading(false);
      })
      .finally(() => {
        setIsLoaded(false);
      });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeLimit = (event) => {
    setLimit(event.target.value);
    setPage(0);
  };

  const onDelete = (id) => {
    confirm({
      title: 'Удаление',
      content: 'Вы уверены, что хотите удалить запись?',
      onConfirm: () => {
        deleteNewsAndOffers(`news-and-offers/${id}`)
          .then((resp) => {
            if (resp.status === 'success') {
              loadNews();
            }
          })
          .catch((e) => {
            console.log(e.response);
          });
      }
    });
  };

  useEffect(() => {
    if (!queryParams.title) {
      searchParams.delete('title');
      setSearchParams(searchParams);
    }
    if (!queryParams.id) {
      searchParams.delete('id');
      setSearchParams(searchParams);
    }
    if (!queryParams.category_id) {
      searchParams.delete('news_and_offers_category_id');
      setSearchParams(searchParams);
    }
  }, [queryParams]);

  useEffect(() => {
    loadNews();
  }, [page, limit, searchParams]);

  if (isLoaded) {
    return (
      <div className="loader">
        <BallTriangle
          height="100"
          width="100"
          color="grey"
          ariaLabel="loading"
        />
      </div>
    );
  }

  return (
    <>
      <Helmet>
        <title>Новости и предложения</title>
      </Helmet>
      <Box className="headerWrapper">
        <Box className="headerTitle">Новости и предложения</Box>
      </Box>
      <Box
        sx={{ backgroundColor: 'background.default', minHeight: '100%', py: 3 }}
      >
        <Container maxWidth={false}>
          {isDataLoading ? (
            <UserListSkelet />
          ) : (
            <>
              <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Box sx={{ marginLeft: 2 }}>
                  <Link to="/app/news-and-offers/add">
                    <Button color="primary" variant="contained">
                      Добавить новость
                    </Button>
                  </Link>
                </Box>
              </Box>
              <Box sx={{ pt: 3 }}>
                <Card>
                  <PerfectScrollbar>
                    <Box sx={{ minWidth: 1400 }}>
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          mx: 2,
                          mb: 1
                        }}
                      >
                        <TextField
                          fullWidth
                          label="Заголовок"
                          margin="normal"
                          name="title"
                          onChange={handleChangeQueryParams}
                          type="text"
                          value={queryParams.title}
                          variant="outlined"
                          style={{ width: '30%' }}
                        />
                        <TextField
                          fullWidth
                          label="Id"
                          margin="normal"
                          name="id"
                          onChange={handleChangeQueryParams}
                          type="text"
                          value={queryParams.id}
                          variant="outlined"
                          style={{ width: '30%' }}
                        />
                        <FormControl
                          margin="normal"
                          style={{
                            width: '30%',
                            alignSelf: 'center',
                            height: '100%'
                          }}
                        >
                          <InputLabel id="demo-select-small-label">
                            Категория
                          </InputLabel>
                          <Select
                            name="category_id"
                            label="Категория"
                            value={queryParams.category_id}
                            onChange={handleChangeQueryParams}
                            variant="outlined"
                            style={{
                              height: '100%'
                            }}
                          >
                            <MenuItem value="">Все</MenuItem>
                            {categories?.map((item) => (
                              <MenuItem
                                key={item.category_id}
                                value={item.category_id}
                              >
                                {item?.category_name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        <Button
                          color="primary"
                          variant="contained"
                          onClick={handleFilterQueryParams}
                          sx={{ mt: 2, mb: 1 }}
                        >
                          Применить
                        </Button>
                      </Box>
                      <Divider />
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell sx={{ width: 80 }}>Id</TableCell>
                            <TableCell>Фото</TableCell>
                            <TableCell>Заголовок</TableCell>
                            <TableCell>Категория</TableCell>
                            <TableCell>Опубликовано</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {newsAndOffers?.map((newsAndOffers) => (
                            <TableRow hover key={newsAndOffers.id}>
                              <TableCell sx={{ width: 80 }}>
                                {newsAndOffers?.id}
                              </TableCell>
                              <TableCell>
                                <Box
                                  sx={{ alignItems: 'center', display: 'flex' }}
                                >
                                  <Avatar
                                    src={
                                      newsAndOffers.photo?.startsWith('http')
                                        ? newsAndOffers.photo
                                        : `${process.env.REACT_APP_API_URL}/uploads/logos/${newsAndOffers.photo}`
                                    }
                                  />
                                </Box>
                              </TableCell>

                              <TableCell>
                                {newsAndOffers?.title || '---'}
                              </TableCell>
                              <TableCell>
                                {newsAndOffers?.news_and_offers_category_name ||
                                  '---'}
                              </TableCell>
                              <TableCell>
                                {newsAndOffers?.publishedAt !== null
                                  ? new Date(
                                      newsAndOffers.publishedAt
                                    ).toLocaleDateString()
                                  : 'Не опубликовано'}
                              </TableCell>

                              <TableCell>
                                <Box sx={{ display: 'flex' }}>
                                  <Box sx={{ display: 'flex' }}>
                                    <Link
                                      to={`/app/news-and-offers/${newsAndOffers.id}`}
                                    >
                                      <Button
                                        color="primary"
                                        variant="contained"
                                        sx={{ ml: 2 }}
                                      >
                                        Инфо.
                                      </Button>
                                    </Link>
                                    <Box sx={{ ml: 2 }}>
                                      <Link
                                        to={`/app/news-and-offers/edit/${newsAndOffers.id}`}
                                      >
                                        <Button
                                          color="primary"
                                          variant="contained"
                                        >
                                          Редакт.
                                        </Button>
                                      </Link>
                                      <Button
                                        sx={{ ml: 2 }}
                                        color="primary"
                                        variant="contained"
                                        onClick={() =>
                                          onDelete(newsAndOffers?.id)
                                        }
                                      >
                                        Удалить
                                      </Button>
                                    </Box>
                                  </Box>
                                </Box>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                        <TableFooter>
                          <TableRow>
                            <TablePagination
                              labelRowsPerPage={
                                <span>Кол-во строк на странице:</span>
                              }
                              rowsPerPageOptions={[10, 20, 30, 40, 50]}
                              colSpan={7}
                              count={count}
                              rowsPerPage={limit}
                              page={page}
                              onPageChange={handleChangePage}
                              onRowsPerPageChange={handleChangeLimit}
                            />
                          </TableRow>
                        </TableFooter>
                      </Table>
                    </Box>
                  </PerfectScrollbar>
                </Card>
              </Box>
            </>
          )}
        </Container>
      </Box>
    </>
  );
};

export default NewsAndOffersList;
