import { Navigate } from 'react-router-dom';
import DashboardLayout from './layouts/DashboardLayout';
import MainLayout from './layouts/MainLayout';
import Account from './pages/Account/Account';
import UserInfo from './pages/User/UserInfo';
import UserEdit from './pages/User/UserEdit';
import UserList from './pages/User/UserList';
import Login from './pages/Login/Login';
import NotFound from './pages/NotFound/NotFound';
import Logs from './pages/Systems/Logs/Logs';
import Dashboard from './pages/Dashboard/Dashboard';
import EmailTemplateList from './pages/Templates/Email-Templates/EmailTemplateList';
import EmailTemplateCreate from './pages/Templates/Email-Templates/EmailTemplateCreate';
import EmailTemplateEdit from './pages/Templates/Email-Templates/EmailTemplateEdit';
import CommonSettings from './pages/Systems/CommonSettings/CommonSettings';
import UpdateSystem from './pages/Systems/UpdateSystem/UpdateSystem';
import SettingsExampl from './pages/Settings/SettingsExampl';
import UserAdd from './pages/User/UserAdd';
import EmailHistoryList from './pages/Templates/Email-Hstory/EmailHistoryList';
import FeedBackList from './pages/FeedBacks/FeedBackList';
import FeedBackEdit from './pages/FeedBacks/FeedBackEdit';
import ConfigurationKeysList from './pages/ConfigurationKeys/ConfigurationKeysList';
import ConfigurationKeysAdd from './pages/ConfigurationKeys/ConfigurationKeysAdd';
import ConfigurationKeysEdit from './pages/ConfigurationKeys/ConfigurationKeysEdit';
import AppLogs from './pages/AppWork/AppLogs';
import AppStatistics from './pages/AppStatistics/AppStatistics';
import AppStatisticsEventsList from './pages/AppStatistics/AppStatisticsEventsList';
import AppStatisticsEventsAdd from './pages/AppStatistics/AppStatisticsEventsAdd';
import AppStatisticsEventsEdit from './pages/AppStatistics/AppStatisticsEventsEdit';
import ShopsList from './pages/Shops/ShopsList';
import ShopAdd from './pages/Shops/ShopAdd';
import ShopEdit from './pages/Shops/ShopEdit';
import ShopInfo from './pages/Shops/ShopInfo';
import FoodList from './pages/Food/FoodList';
import FoodAdd from './pages/Food/FoodAdd';
import FoodEdit from './pages/Food/FoodEdit';
import FoodInfo from './pages/Food/FoodInfo';
import ServiceList from './pages/Services/ServiceList';
import ServiceAdd from './pages/Services/ServiceAdd';
import ServiceEdit from './pages/Services/ServiceEdit';
import ServiceInfo from './pages/Services/ServiceInfo';
import AdministrationAdd from './pages/Administration/AdministrationAdd';
import AdministrationEdit from './pages/Administration/AdministrationEdit';
import AdministrationInfo from './pages/Administration/AdministrationInfo';
import AdministrationList from './pages/Administration/AdministrationList';
import CategoriesList from './pages/Categories/CategoriesList';
import NewsAndOffersList from './pages/NewsAndOffers/NewsAndOffersList';
import NewsAndOffersAdd from './pages/NewsAndOffers/NewsAndOffersAdd';
import NewsAndOffersEdit from './pages/NewsAndOffers/NewsAndOffersEdit';
import NewsAndOffersInfo from './pages/NewsAndOffers/NewsAndOffersInfo';
import RenterList from './pages/Renters/RenterList';
import RenterInfo from './pages/Renters/RenterInfo';
import BannerList from './pages/Banners/BannerList';
import BannerInfo from './pages/Banners/BannerInfo';
import BannerEdit from './pages/Banners/BannerEdit';
import BannerAdd from './pages/Banners/BannerAdd';

const routes = [
  {
    path: 'app',
    element: <DashboardLayout />,
    children: [
      { path: 'account', element: <Account /> },
      { path: 'dashboard', element: <Dashboard /> },

      { path: 'user/:id', element: <UserInfo /> },
      { path: 'user/edit/:id', element: <UserEdit /> },
      { path: 'user/add', element: <UserAdd /> },
      { path: 'users', element: <UserList /> },

      { path: 'shops', element: <ShopsList /> },
      { path: 'shops/add', element: <ShopAdd /> },
      { path: 'shop/edit/:id', element: <ShopEdit /> },
      { path: 'shop/:id', element: <ShopInfo /> },

      { path: 'food', element: <FoodList /> },
      { path: 'food/add', element: <FoodAdd /> },
      { path: 'food/edit/:id', element: <FoodEdit /> },
      { path: 'food/:id', element: <FoodInfo /> },

      { path: 'services', element: <ServiceList /> },
      { path: 'services/add', element: <ServiceAdd /> },
      { path: 'services/edit/:id', element: <ServiceEdit /> },
      { path: 'services/:id', element: <ServiceInfo /> },

      { path: 'banners', element: <BannerList /> },
      { path: 'banners/add', element: <BannerAdd /> },
      { path: 'banners/edit/:id', element: <BannerEdit /> },
      { path: 'banners/:id', element: <BannerInfo /> },

      { path: 'administration', element: <AdministrationList /> },
      { path: 'administration/add', element: <AdministrationAdd /> },
      { path: 'administration/edit/:id', element: <AdministrationEdit /> },
      { path: 'administration/:id', element: <AdministrationInfo /> },

      { path: 'news-and-offers', element: <NewsAndOffersList /> },
      { path: 'news-and-offers/add', element: <NewsAndOffersAdd /> },
      { path: 'news-and-offers/edit/:id', element: <NewsAndOffersEdit /> },
      { path: 'news-and-offers/:id', element: <NewsAndOffersInfo /> },

      { path: 'renters', element: <RenterList /> },
      { path: 'renters/:id', element: <RenterInfo /> },

      { path: 'categories', element: <CategoriesList /> },

      { path: 'feedbacks/edit/:id', element: <FeedBackEdit /> },
      { path: 'feedbacks', element: <FeedBackList /> },

      { path: 'email-templates', element: <EmailTemplateList /> },
      { path: 'email-history', element: <EmailHistoryList /> },
      { path: 'email-templates/create', element: <EmailTemplateCreate /> },
      { path: 'email-templates/edit/:id', element: <EmailTemplateEdit /> },

      { path: 'app-logs', element: <AppLogs /> },

      { path: 'app-statistics', element: <AppStatistics /> },
      { path: 'app-statistics/events', element: <AppStatisticsEventsList /> },
      {
        path: 'app-statistics/events/add',
        element: <AppStatisticsEventsAdd />
      },
      {
        path: 'app-statistics/events/edit/:id',
        element: <AppStatisticsEventsEdit />
      },

      { path: 'logs', element: <Logs /> },
      { path: 'common-settings', element: <CommonSettings /> },
      { path: 'update-system', element: <UpdateSystem /> },

      { path: 'settings', element: <SettingsExampl /> },

      { path: 'configuration/keys', element: <ConfigurationKeysList /> },
      { path: 'configuration/keys/add', element: <ConfigurationKeysAdd /> },
      {
        path: 'configuration/keys/edit/:id',
        element: <ConfigurationKeysEdit />
      },

      { path: '', element: <Navigate to="/app/users" /> },
      { path: '*', element: <Navigate to="/404" /> }
    ]
  },
  {
    path: '/',
    element: <MainLayout />,
    children: [
      { path: 'login', element: <Login /> },
      { path: '404', element: <NotFound /> },
      { path: '/', element: <Navigate to="/app/users" /> },
      { path: '*', element: <Navigate to="/404" /> }
    ]
  }
];

export default routes;
