import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import {
  Box,
  Avatar,
  Container,
  Button,
  Card,
  CardContent
} from '@material-ui/core';
import ChevronLeft from '@material-ui/icons/ChevronLeft';

import { Link as RouterLink, useParams, useNavigate } from 'react-router-dom';
import { useGet } from '../../API/request';
import '../../styles/All.css';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { BallTriangle } from 'react-loader-spinner';

const BannerInfo = () => {
  const navigate = useNavigate();
  const getBanners = useGet();
  const { id } = useParams();

  const [isLoaded, setIsLoaded] = useState(true);

  const [banners, setBanners] = useState({});

  useEffect(() => {
    getBanners(`banners/${id}`)
      .then((resp) => {
        if (resp.status === 'success') {
          setBanners(resp.data.banner);

          setIsLoaded(true);
        }
      })
      .catch(() => {});
  }, []);

  if (!isLoaded) {
    return (
      <div className="loader">
        <BallTriangle
          height="100"
          width="100"
          color="grey"
          ariaLabel="loading"
        />
      </div>
    );
  }

  return (
    <>
      <Helmet>
        <title>Баннер</title>
      </Helmet>
      <Box className="headerWrapper">
        <Box className="headerTitle">
          <Button startIcon={<ChevronLeft />} onClick={() => navigate(-1)}>
            Назад
          </Button>
          Баннер
        </Box>
        <ul className="headerList">
          <li onClick={() => navigate(-1)}>Баннеры</li>
          <li>/</li>
          <li>Информация</li>
        </ul>
      </Box>

      <Box sx={{ backgroundColor: 'background.default', pt: 3, pb: 1 }}>
        <Container maxWidth={false}>
          <Card>
            <CardContent sx={{ p: 3 }}>
              <PerfectScrollbar>
                <div className="wrapAvatar">
                  <div className="avatar-block">
                    <Avatar
                      src={`${process.env.REACT_APP_API_URL}/uploads/logos/${banners?.photo}`}
                      className="avatar"
                    />
                  </div>
                  <div className="info-block">
                    <div className="wrap">
                      <div className="label">ID:</div>
                      <div className="text">{banners?.id || '---'}</div>
                    </div>
                    <div className="wrap">
                      <div className="label">Заголовок:</div>
                      <div className="text">{banners?.heading || '---'}</div>
                    </div>

                    <div className="wrap">
                      <div className="label">Описание:</div>
                      <div className="text">
                        {banners?.description || '---'}
                      </div>
                    </div>

                    <div className="wrap">
                      <div className="label">Ссылка:</div>
                      <div className="text">{banners?.link || '---'}</div>
                    </div>
                  </div>
                </div>
              </PerfectScrollbar>
            </CardContent>
          </Card>
        </Container>
      </Box>
    </>
  );
};

export default BannerInfo;
