import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { usePost } from '../../API/request';
import {
  Box,
  Container,
  Avatar,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button,
  TableFooter,
  TablePagination,
  TextField,
  Divider,
  Select,
  InputLabel,
  MenuItem,
  FormControl
} from '@material-ui/core';
import PerfectScrollbar from 'react-perfect-scrollbar';
import UserListSkelet from '../../skeletons/UserListSkelet';
import { useDelete, useGet } from '../../API/request';
import { useConfirm } from '../../components/Confirm/index';
import { BallTriangle } from 'react-loader-spinner';
import Alert from '@material-ui/core/Alert';

import '../../styles/All.css';

const CategoriesList = () => {
  const confirm = useConfirm();

  const getCategories = useGet();
  const deleteFood = useDelete();

  const [isLoaded, setIsLoaded] = useState(false);
  const [isDataLoading, setIsDataLoading] = useState(true);
  const [section, setSection] = useState('shops');
  const [newCategory, setNewCategory] = useState('');
  const [categories, setCategories] = useState([]);
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [count, setCount] = useState(0);

  const sections = [
    { name: 'Магазины', value: 'shops' },
    { name: 'Еда', value: 'food' },
    { name: 'Услуги', value: 'services' },
    { name: 'Новости и предложения', value: 'news-and-offers' }
  ];

  const [alert, setAlert] = useState({
    txt: '',
    isVisible: false,
    type: 'error'
  });
  const postNewCategory = usePost();

  const loadData = () => {
    let endpoint = `categories/${section}?page=${page + 1}&limit=${limit}`;
    getCategories(endpoint)
      .then((resp) => {
        if (resp.status === 'success') {
          setCategories(resp.data.categories.data);
          setIsDataLoading(false);
          setCount(resp.data.currentCount || 0);
          setIsLoaded(true);
        }
      })
      .catch((err) => {
        console.log(err.response);
        setCount(0);
        setIsDataLoading(false);
      });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeLimit = (event) => {
    setLimit(event.target.value);
    setPage(0);
  };

  const onDelete = (id) => {
    confirm({
      title: 'Удаление',
      content: 'Вы уверены, что хотите удалить категорию?',
      onConfirm: () => {
        deleteFood(`categories/${section}/${id}`)
          .then((resp) => {
            if (resp.status === 'success') {
              loadData();
            }
          })
          .catch((e) => {
            showAlert(
              'error',
              'Ошибка удаления категории: есть записи, которые относятся к данной категории'
            );
          });
      }
    });
  };

  const showAlert = (type, text) => {
    setAlert({
      txt: text,
      type,
      isVisible: true
    });

    setTimeout(() => {
      setAlert({
        txt: text,
        type,
        isVisible: false
      });
    }, 1400);
  };

  const postCategory = async () => {
    postNewCategory(`categories/${section}`, {
      category_name: newCategory
    }).then((resp) => {
      if (resp.status === 'success') {
        showAlert('success', 'Категория добавлена');
        loadData();
        setIsLoaded(true);
      } else {
        showAlert('error', 'Ошибка');
      }
    });
  };

  useEffect(() => {
    setIsLoaded(false);
    loadData();
  }, [section, page, limit]);

  if (!isLoaded) {
    return (
      <div className="loader">
        <BallTriangle
          height="100"
          width="100"
          color="grey"
          ariaLabel="loading"
        />
      </div>
    );
  }

  return (
    <>
      <Helmet>
        <title>Категории</title>
      </Helmet>
      <Box className="headerWrapper">
        <Box className="headerTitle">Редактировать категории</Box>
      </Box>
      <Box
        sx={{ backgroundColor: 'background.default', minHeight: '100%', py: 3 }}
      >
        <Container maxWidth={false}>
          {isDataLoading ? (
            <UserListSkelet />
          ) : (
            <>
              <Box sx={{ pt: 3 }}>
                <Card>
                  <PerfectScrollbar>
                    <Box>
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          mx: 2,
                          mb: 1
                        }}
                      >
                        <FormControl
                          margin="normal"
                          style={{
                            width: '30%',
                            alignSelf: 'center',
                            height: '100%'
                          }}
                        >
                          <InputLabel id="demo-select-small-label">
                            Раздел
                          </InputLabel>
                          <Select
                            name="category"
                            label="Раздел"
                            value={section}
                            onChange={(e) => setSection(e.target.value)}
                            variant="outlined"
                            style={{
                              height: '100%'
                            }}
                          >
                            {sections?.map((item) => (
                              <MenuItem key={item.value} value={item.value}>
                                {item.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        <TextField
                          fullWidth
                          label="Название новой категории"
                          margin="normal"
                          name="name"
                          onChange={(e) => setNewCategory(e.target.value)}
                          type="text"
                          value={newCategory}
                          variant="outlined"
                          style={{ width: '50%' }}
                        />
                        <Button
                          color="primary"
                          variant="contained"
                          type="button"
                          onClick={() => postCategory()}
                          sx={{ mt: 2, mb: 1 }}
                        >
                          Добавить категорию
                        </Button>
                      </Box>

                      <Alert
                        severity={alert.type}
                        style={{ display: alert.isVisible ? 'flex' : 'none' }}
                      >
                        {alert.txt}
                      </Alert>
                      <Divider />
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell>Id</TableCell>
                            <TableCell>Категория</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody sx={{ width: '100%' }}>
                          {categories?.map((item) => (
                            <TableRow
                              sx={{ width: '100%' }}
                              hover
                              key={item.id}
                            >
                              <TableCell>{item?.category_id}</TableCell>
                              <TableCell>
                                {item?.category_name || '---'}
                              </TableCell>
                              <TableCell
                                sx={{
                                  width: '100%',
                                  display: 'flex',
                                  justifyContent: 'end'
                                }}
                              >
                                <Box
                                  sx={{ display: 'flex', justifySelf: 'end' }}
                                >
                                  <Box
                                    sx={{ display: 'flex', justifySelf: 'end' }}
                                  >
                                    {/* <Box sx={{ ml: 2 }}>
                                      <Link to={`/app/food/edit/${food.id}`}>
                                        <Button
                                          color="primary"
                                          variant="contained"
                                        >
                                          Редакт.
                                        </Button>
                                      </Link> */}
                                    <Button
                                      sx={{ ml: 2 }}
                                      color="primary"
                                      variant="contained"
                                      onClick={() =>
                                        onDelete(item?.category_id)
                                      }
                                    >
                                      Удалить
                                    </Button>
                                  </Box>
                                </Box>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                        <TableFooter>
                          <TableRow>
                            <TablePagination
                              labelRowsPerPage={
                                <span>Кол-во строк на странице:</span>
                              }
                              rowsPerPageOptions={[10, 20, 30, 40, 50]}
                              colSpan={7}
                              count={count}
                              rowsPerPage={limit}
                              page={page}
                              onPageChange={handleChangePage}
                              onRowsPerPageChange={handleChangeLimit}
                            />
                          </TableRow>
                        </TableFooter>
                      </Table>
                    </Box>
                  </PerfectScrollbar>
                </Card>
              </Box>
            </>
          )}
        </Container>
      </Box>
    </>
  );
};

export default CategoriesList;
