import { Helmet } from 'react-helmet';
import {
  Box,
  Container,
  Button,
  TextField,
  CardContent
} from '@material-ui/core';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import { useNavigate } from 'react-router-dom';
import { usePost } from '../../API/request';
import React, { useState } from 'react';
import { BallTriangle } from 'react-loader-spinner';

import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import Divider from '@material-ui/core/Divider';
import Alert from '@material-ui/core/Alert';

const BannerAdd = () => {
  const navigate = useNavigate();
  const postBanner = usePost();
  const postLogo = usePost();

  const [isLoaded, setIsLoaded] = useState(true);

  const [uploadedImg, setUploadedImg] = useState('/static/images/defphoto.jpg');
  const [values, setValues] = useState({
    heading: '',
    description: '',
    link: '',
    photo: ''
  });
  const [errors, setErrors] = useState({
    heading: false,
    description: false,
    photo: false,
    link: false
  });

  const [isShowLoader, setIsShowLoader] = useState(false);
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const [logoUrl, setLogoUrl] = useState('');

  const [alert, setAlert] = useState({
    txt: '',
    isVisible: false,
    type: 'error'
  });

  const avaUploaded = (event) => {
    setUploadedImg(URL.createObjectURL(event.target.files[0]));
    setValues({
      ...values,
      photo: event.target.files[0]
    });
  };

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
    setErrors({
      ...errors,
      [event.target.name]: false
    });
  };

  const showAlert = (type, text) => {
    setAlert({
      txt: text,
      type,
      isVisible: true
    });

    setTimeout(() => {
      setAlert({
        txt: text,
        type,
        isVisible: false
      });

      setSubmitDisabled(false);
    }, 1400);
  };

  const validate = () => {
    let validComplete = true;
    let formErrors = { ...errors };

    if (values.heading === '') {
      validComplete = false;
      formErrors.heading = false;
      showAlert('error', 'Заголовок не должен быть пустым');
    }

    if (values.description === '') {
      validComplete = false;
      formErrors.description = false;
      showAlert('error', 'Описание не должно быть пустым');
    }

    if (values.link === '') {
      validComplete = false;
      formErrors.link = false;
      showAlert('error', 'Поле Ссылка не должно быть пустым');
    }

    setErrors(formErrors);
    return validComplete;
  };

  const clearForm = () => {
    setValues({
      heading: '',
      description: '',
      link: ''
    });
  };

  const uploadLogo = async () => {
    let data = new FormData();
    data.append('photo', values.photo);

    postLogo(`banners/photo/new`, data)
      .then((resp) => {
        if (resp.status === 'success') {
          showAlert('success', 'Данные успешно обновленны');
          setLogoUrl(resp.data.file);
          console.log(resp);
        } else {
          showAlert('error', 'Ошибка');
        }
      })
      .catch((err) => {
        showAlert('error', 'Ошибка сервера');
      })
      .finally(() => {
        setSubmitDisabled(false);
      });
  };

  const submit = async () => {
    if (validate()) {
      setIsShowLoader(true);
      setSubmitDisabled(true);

      let data = {
        photo: logoUrl,
        heading: values.heading,
        description: values.description,
        link: values.link
      };

      postBanner('banners', data)
        .then((resp) => {
          if (resp.status === 'success') {
            showAlert('success', 'Баннер добавлен');
            clearForm();
          } else {
            showAlert('error', 'Ошибка');
          }
        })
        .catch((err) => {
          showAlert('error', 'Ошибка сервера');
          setIsShowLoader(false);
          setSubmitDisabled(false);
        })
        .finally(() => {});
    }
  };

  if (!isLoaded) {
    return (
      <div className="loader">
        <BallTriangle
          height="100"
          width="100"
          color="grey"
          ariaLabel="loading"
        />
      </div>
    );
  }

  return (
    <>
      <Helmet>
        <title>Добавить баннер</title>
      </Helmet>
      <Box sx={{ pt: 2 }}>
        <Container maxWidth={false}>
          <Button startIcon={<ChevronLeft />} onClick={() => navigate(-1)}>
            Back
          </Button>
        </Container>
      </Box>
      <Box
        sx={{ backgroundColor: 'background.default', minHeight: '100%', pt: 3 }}
      >
        <Container maxWidth={false}>
          <Box sx={{ mb: 1 }}>
            <Alert
              severity={alert.type}
              style={{ display: alert.isVisible ? 'flex' : 'none' }}
            >
              {alert.txt}
            </Alert>
          </Box>
          <form>
            <Card>
              <CardHeader title="Редактирование фото" />
              <Divider />
              <CardContent sx={{ position: 'relative' }}>
                <div className="itemWrapper">
                  <div className="container">
                    <input
                      accept="xlsx/*"
                      type="file"
                      style={{ display: 'none' }}
                      id={1}
                      onChange={(event) => {
                        setSubmitDisabled(true);
                        avaUploaded(event, 1);
                      }}
                    />
                    <label htmlFor={1}>
                      <img src={uploadedImg} className="itemImg" />
                      <div className="middle" />
                    </label>
                  </div>
                  <div className="help-text">
                    Доступны следующие расширения: .png .jpg .svg .bmp .tga
                    .webp
                  </div>
                </div>
              </CardContent>
              <Divider />
              <Box sx={{ display: 'flex', justifyContent: 'flex-end', p: 2 }}>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={uploadLogo}
                >
                  Сохранить
                </Button>
              </Box>
            </Card>
          </form>
        </Container>

        <Container maxWidth={false}>
          <Box sx={{ pt: 2 }}>
            <form>
              <Card>
                <CardHeader title="Добавление нового баннера" />
                <Divider />
                <CardContent sx={{ position: 'relative' }}>
                  <TextField
                    fullWidth
                    label="Заголовок"
                    margin="normal"
                    name="heading"
                    onChange={handleChange}
                    type="text"
                    value={values.heading}
                    variant="outlined"
                    error={errors.heading}
                  />
                  <TextField
                    fullWidth
                    label="Описание"
                    margin="normal"
                    name="description"
                    onChange={handleChange}
                    type="text"
                    value={values.description}
                    variant="outlined"
                    error={errors.description}
                  />
                  <TextField
                    fullWidth
                    label="Ссылка"
                    margin="normal"
                    name="link"
                    onChange={handleChange}
                    type="link"
                    value={values.link}
                    variant="outlined"
                    error={errors.link}
                  />

                  <Alert
                    severity={alert.type}
                    style={{ display: alert.isVisible ? 'flex' : 'none' }}
                  >
                    {alert.txt}
                  </Alert>
                </CardContent>
                <Divider />
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', p: 2 }}>
                  <Button
                    disabled={submitDisabled}
                    color="primary"
                    variant="contained"
                    onClick={submit}
                  >
                    Добавить
                  </Button>
                </Box>
              </Card>
            </form>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default BannerAdd;
