import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import {
  Box,
  Avatar,
  Container,
  Button,
  Card,
  CardContent
} from '@material-ui/core';
import ChevronLeft from '@material-ui/icons/ChevronLeft';

import { Link as RouterLink, useParams, useNavigate } from 'react-router-dom';
import { useGet } from '../../API/request';
import '../../styles/All.css';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { BallTriangle } from 'react-loader-spinner';

const FoodInfo = () => {
  const navigate = useNavigate();
  const getFood = useGet();
  const { id } = useParams();

  const [isLoaded, setIsLoaded] = useState(true);

  const [food, setFood] = useState({});

  useEffect(() => {
    setIsLoaded(true);

    getFood(`food/${id}`)
      .then((resp) => {
        if (resp.status === 'success') {
          setFood(resp.data.food);
        }
      })
      .catch(() => {})
      .finally(() => {
        setIsLoaded(false);
      });
  }, []);

  if (isLoaded) {
    return (
      <div className="loader">
        <BallTriangle
          height="100"
          width="100"
          color="grey"
          ariaLabel="loading"
        />
      </div>
    );
  }

  return (
    <>
      <Helmet>
        <title>Ресторан</title>
      </Helmet>
      <Box className="headerWrapper">
        <Box className="headerTitle">
          <Button startIcon={<ChevronLeft />} onClick={() => navigate(-1)}>
            Назад
          </Button>
          Еда
        </Box>
        <ul className="headerList">
          <li onClick={() => navigate(-1)}>Магазины</li>
          <li>/</li>
          <li>Информация</li>
        </ul>
      </Box>

      <Box sx={{ backgroundColor: 'background.default', pt: 3, pb: 1 }}>
        <Container maxWidth={false}>
          <Card>
            <CardContent sx={{ p: 3 }}>
              <PerfectScrollbar>
                <div className="wrapAvatar">
                  <div className="avatar-block">
                    <Avatar
                      src={
                        food.logo.startsWith('http')
                          ? food.logo
                          : `${process.env.REACT_APP_API_URL}/uploads/logos/${food.logo}`
                      }
                      className="avatar"
                    />
                  </div>
                  <div className="info-block">
                    <div className="wrap">
                      <div className="label">ID:</div>
                      <div className="text">{food?.id || '---'}</div>
                    </div>
                    <div className="wrap">
                      <div className="label">Название:</div>
                      <div className="text">{food?.name || '---'}</div>
                    </div>

                    <div className="wrap">
                      <div className="label">Описание:</div>
                      <div className="text">{food?.description || '---'}</div>
                    </div>

                    <div className="wrap">
                      <div className="label">Время работы:</div>
                      <div className="text">{food?.working_hours || '---'}</div>
                    </div>
                    <div className="wrap">
                      <div className="label">Веб-сайт:</div>
                      <div className="text">{food?.website || '---'}</div>
                    </div>
                    <div className="wrap">
                      <div className="label">Соц.сети:</div>
                      <div className="text">
                        {food?.social_media_link || '---'}
                      </div>
                    </div>
                    <div className="wrap">
                      <div className="label">Категория:</div>
                      <div className="text">
                        {food.food_category_name || '---'}
                      </div>
                    </div>
                  </div>
                </div>
              </PerfectScrollbar>
            </CardContent>
          </Card>
        </Container>
      </Box>
    </>
  );
};

export default FoodInfo;
