import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import {
  Box,
  Container,
  Button,
  Card,
  CardHeader,
  Divider,
  CardContent,
  CircularProgress,
  TextField,
  Alert,
  Grid
} from '@material-ui/core';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import { useParams, useNavigate } from 'react-router-dom';
import { useGet, usePost, usePut } from '../../API/request';
import { BallTriangle } from 'react-loader-spinner';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import '../../styles/Avatar/style.css';

const ShopEdit = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const getOne = useGet();
  const getCategories = useGet();
  const postLogo = usePost();
  const putShop = usePut();
  const [isLoaded, setIsLoaded] = useState(true);
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const [nothingChanged, setNothingChanged] = useState(true);

  const [uploadedImg, setUploadedImg] = useState('/static/images/defphoto.jpg');
  const [values, setValues] = useState({
    name: '',
    logo: uploadedImg,
    description: '',
    working_hours: '',
    website: '',
    social_media_link: '',
    shop_category_id: '',
    logo: ''
  });
  const [errors, setErrors] = useState({
    name: false,
    logo: false,
    description: false,
    working_hours: false,
    website: false,
    social_media_link: false,
    shop_category_id: false,
    logo: false
  });
  const [categories, setCategories] = useState([]);

  const [alert, setAlert] = useState({
    txt: '',
    isVisible: false,
    type: 'error'
  });

  const handleChange = (event) => {
    setNothingChanged(false);

    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
    setErrors({
      ...errors,
      [event.target.name]: false
    });
  };

  const avaUploaded = (event) => {
    setNothingChanged(false);
    setUploadedImg(URL.createObjectURL(event.target.files[0]));
    setValues({
      ...values,
      logo: event.target.files[0]
    });
  };

  const showAlert = (type, text) => {
    setAlert({
      txt: text,
      type,
      isVisible: true
    });

    setTimeout(() => {
      setAlert({
        txt: text,
        type,
        isVisible: false
      });

      setSubmitDisabled(false);
    }, 2500);
  };

  const validateInfo = () => {
    let validComplete = true;
    let formErrors = { ...errors };

    if (values.name === '') {
      validComplete = false;
      formErrors.name = false;
      showAlert('error', 'Название не должно быть пустым');
    }

    if (values.description === '') {
      validComplete = false;
      formErrors.description = false;
      showAlert('error', 'Описание не должно быть пустым');
    }

    if (values.working_hours === '') {
      validComplete = false;
      formErrors.working_hours = false;
      showAlert('error', 'Поле Рабочие Часы не должно быть пустым');
    }

    if (values.website === '') {
      validComplete = false;
      formErrors.website = false;
      showAlert('error', 'Поле Веб-сайт не должно быть пустым');
    }
    if (values.social_media_link === '') {
      validComplete = false;
      formErrors.social_media_link = false;
      showAlert('error', 'Поле Соц.сети не должно быть пустым');
    }
    if (values.category_id === '') {
      validComplete = false;
      formErrors.category_id = false;
      showAlert('error', 'Поле Категория не должно быть пустым');
    }

    setErrors(formErrors);
    return validComplete;
  };

  const uploadLogo = async () => {
    if (nothingChanged) {
      showAlert('error', 'Нет изменений');
      return;
    }

    setSubmitDisabled(true);

    let data = new FormData();
    data.append('logo', values.logo);
    data.append('fields', values);

    postLogo(`shops/${id}/logo`, data)
      .then((resp) => {
        if (resp.status === 'success') {
          showAlert('success', 'Данные успешно обновленны');
        } else {
          showAlert('error', 'Ошибка');
        }
      })
      .catch((err) => {
        showAlert('error', 'Ошибка сервера');
      })
      .finally(() => {
        setSubmitDisabled(false);
      });
  };

  const submitInfo = async () => {
    if (nothingChanged) {
      showAlert('error', 'Нет изменений');
      return;
    }

    if (validateInfo()) {
      setSubmitDisabled(true);

      const data = {
        name: values.name,
        description: values.description,
        working_hours: values.working_hours,
        website: values.website,
        shop_category_id: values.shop_category_id,
        social_media_link: values.social_media_link
      };

      putShop(`shops/${id}`, data)
        .then((resp) => {
          if (resp.status === 'success') {
            showAlert('success', 'Данные успешно обновленны');
          } else {
            showAlert('error', 'Ошибка');
          }
        })
        .catch((err) => {
          showAlert('error', 'Ошибка сервера');
        })
        .finally(() => {
          setSubmitDisabled(false);
        });
    }
  };

  useEffect(() => {
    getOne(`shops/${id}`)
      .then((resp) => {
        if (resp.status === 'success') {
          const data = {
            logo: resp.data.shop.logo,
            name: resp.data.shop.name,
            description: resp.data.shop.description,
            website: resp.data.shop.website,
            social_media_link: resp.data.shop.social_media_link,
            working_hours: resp.data.shop.working_hours,
            shop_category_id: resp.data.shop.shop_category_id
          };

          setValues(data);
          const logo = resp.data.shop.logo
            ? `${process.env.REACT_APP_API_URL}/uploads/logos/${resp.data.shop.logo}`
            : '';

          setUploadedImg(logo);
        }
      })
      .catch(() => {
        showAlert(
          'error',
          'Произошла ошибка при загрузке ролей, попробуйте перезайти'
        );
      })
      .finally(() => {});

    getCategories('categories/shops')
      .then((resp) => {
        if (resp.status === 'success') {
        
          setCategories(resp.data.categories.data);
        }
      })
      .catch(() => {
        showAlert(
          'error',
          'Произошла ошибка при загрузке категорий, попробуйте перезайти'
        );
      })
      .finally(() => {
        setIsLoaded(false);
      });
    setIsLoaded(true);
  }, []);

  if (isLoaded) {
    return (
      <div className="loader">
        <BallTriangle
          height="100"
          width="100"
          color="grey"
          ariaLabel="loading"
        />
      </div>
    );
  }

  return (
    <>
      <Helmet>
        <title>Редактировать магазин</title>
      </Helmet>
      <Box className="headerWrapper">
        <Box className="headerTitle">
          <Button startIcon={<ChevronLeft />} onClick={() => navigate(-1)}>
            Назад
          </Button>
          Магазины
        </Box>
        <ul className="headerList">
          <li onClick={() => navigate(-1)}>Магазины</li>
          <li>/</li>
          <li>Редактирование</li>
        </ul>
      </Box>

      {/*image*/}
      <Box sx={{ backgroundColor: 'background.default', pt: 3 }}>
        <Container maxWidth={false}>
          <Box sx={{ mb: 1 }}>
            <Alert
              severity={alert.type}
              style={{ display: alert.isVisible ? 'flex' : 'none' }}
            >
              {alert.txt}
            </Alert>
          </Box>
          <Box
            sx={{
              paddingBottom: 1
            }}
          >
            <form>
              <Card>
                <CardHeader title="Редактирование логотипа" />
                <Divider />
                <CardContent sx={{ position: 'relative' }}>
                  <div className="itemWrapper">
                    <div className="container">
                      <input
                        accept=".png, .jpg, .svg, .bmp, .tga, .webp"
                        type="file"
                        style={{ display: 'none' }}
                        id={1}
                        onChange={(event) => avaUploaded(event, 1)}
                      />
                      <label htmlFor={1}>
                        <img src={uploadedImg} className="itemImg" />
                        <div className="middle" />
                      </label>
                    </div>
                    <div className="help-text">
                      Доступны следующие расширения: .png .jpg .svg .bmp .tga
                      .webp
                    </div>
                  </div>
                </CardContent>
                <Divider />
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', p: 2 }}>
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={uploadLogo}
                    disabled={submitDisabled}
                  >
                    Сохранить
                  </Button>
                </Box>
              </Card>
            </form>
          </Box>
        </Container>

        {/*info*/}
        <Card>
          <CardHeader title="Редактирование магазина" />
          <Divider />
          <CardContent sx={{ position: 'relative' }}>
            <TextField
              fullWidth
              label="Название"
              margin="normal"
              name="name"
              onChange={handleChange}
              type="text"
              value={values.name}
              variant="outlined"
              error={errors.name}
            />
            <TextField
              fullWidth
              label="Описание"
              margin="normal"
              name="description"
              onChange={handleChange}
              type="text"
              value={values.description}
              variant="outlined"
              error={errors.description}
            />
            <TextField
              fullWidth
              label="Рабочее время"
              margin="normal"
              name="working_hours"
              onChange={handleChange}
              type="working_hours"
              value={values.working_hours}
              variant="outlined"
              error={errors.working_hours}
            />
            <TextField
              fullWidth
              label="Веб-сайт"
              margin="normal"
              name="website"
              onChange={handleChange}
              value={values.website}
              variant="outlined"
              error={errors.website}
            />
            <TextField
              fullWidth
              label="Соц.сеть"
              margin="normal"
              name="social_media_link"
              onChange={handleChange}
              value={values.social_media_link}
              variant="outlined"
              error={errors.social_media_link}
            />
            <FormControl fullWidth sx={{ mt: 2, mb: 1 }}>
              <InputLabel id="category">Категория</InputLabel>
              <Select
                labelId="category"
                name="shop_category_id"
                value={values.shop_category_id}
                label="category"
                onChange={handleChange}
              >
                {categories?.map((item) => (
                  <MenuItem key={item.category_id} value={item.category_id}>
                    {item.category_name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <Alert
              severity={alert.type}
              style={{ display: alert.isVisible ? 'flex' : 'none' }}
            >
              {alert.txt}
            </Alert>
          </CardContent>
          <Divider />
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', p: 2 }}>
            <Button
              color="primary"
              variant="contained"
              onClick={submitInfo}
              disabled={submitDisabled}
            >
              Сохранить
            </Button>
          </Box>
        </Card>
      </Box>
    </>
  );
};

export default ShopEdit;
