import { Helmet } from 'react-helmet';
import {
  Box,
  Container,
  Button,
  TextField,
  CardContent
} from '@material-ui/core';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import { useNavigate } from 'react-router-dom';
import { usePost } from '../../API/request';
import React, { useEffect, useState } from 'react';
import { BallTriangle } from 'react-loader-spinner';

import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import Divider from '@material-ui/core/Divider';
import Alert from '@material-ui/core/Alert';

const AdministrationAdd = () => {
  const navigate = useNavigate();
  const postService = usePost();
  const postPhoto = usePost();

  const [uploadedImg, setUploadedImg] = useState('/static/images/defphoto.jpg');
  const [isValidateEmail, setIsValidateEmail] = useState(true);

  const [values, setValues] = useState({
    name: '',
    title: '',
    email: ''
  });
  const [errors, setErrors] = useState({
    name: false,
    title: false,
    email: false
  });

  const [isShowLoader, setIsShowLoader] = useState(false);
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const [photoUrl, setPhotoUrl] = useState('');

  const [alert, setAlert] = useState({
    txt: '',
    isVisible: false,
    type: 'error'
  });

  const avaUploaded = (event) => {
    setUploadedImg(URL.createObjectURL(event.target.files[0]));
    setValues({
      ...values,
      photo: event.target.files[0]
    });
  };

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
    setErrors({
      ...errors,
      [event.target.name]: false
    });
  };

  const showAlert = (type, text) => {
    setAlert({
      txt: text,
      type,
      isVisible: true
    });

    setTimeout(() => {
      setAlert({
        txt: text,
        type,
        isVisible: false
      });

      setSubmitDisabled(false);
    }, 1400);
  };

  const handleChangeEmail = (event) => {
    const reg =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    setIsValidateEmail(!!event.target.value.match(reg));
    setValues({
      ...values,
      email: event.target.value
    });
  };

  const validate = () => {
    let validComplete = true;
    let formErrors = { ...errors };

    if (values.name === '') {
      validComplete = false;
      formErrors.name = false;
      showAlert('error', 'Название не должно быть пустым');
    }

    if (values.title === '') {
      validComplete = false;
      formErrors.title = false;
      showAlert('error', 'Поле Должность не должно быть пустым');
    }

    if (values.email === '') {
      validComplete = false;
      formErrors.email = false;
      showAlert('error', 'Поле email не должно быть пустым');
    } else if (!isValidateEmail) {
      validComplete = false;
      formErrors.email = false;
      showAlert('error', 'Вы передели в поле email не корректные данные');
    }

    setErrors(formErrors);
    return validComplete;
  };

  const clearForm = () => {
    setValues({
      name: '',
      title: '',
      email: '',
      photo: ''
    });
  };

  const uploadPhoto = async () => {
    let data = new FormData();
    data.append('photo', values.photo);

    postPhoto(`administration/photo/new`, data)
      .then((resp) => {
        if (resp.status === 'success') {
          showAlert('success', 'Данные успешно обновленны');
          setPhotoUrl(resp.data.file);
        } else {
          showAlert('error', 'Ошибка');
        }
      })
      .catch((err) => {
        showAlert('error', 'Ошибка сервера');
      })
      .finally(() => {
        setSubmitDisabled(false);
      });
  };

  const submit = async () => {
    if (validate()) {
      setIsShowLoader(true);
      setSubmitDisabled(true);

      let data = {
        photo: photoUrl,
        name: values.name,
        title: values.title,
        email: values.email
      };

      postService('administration', data)
        .then((resp) => {
          if (resp.status === 'success') {
            showAlert('success', 'Работник добавлен');
            clearForm();
            setIsShowLoader(false);
          } else {
            showAlert('error', 'Ошибка');
          }
        })
        .catch((err) => {
          showAlert('error', 'Ошибка сервера');
          setIsShowLoader(false);
          setSubmitDisabled(false);
        })
        .finally(() => {});
    }
  };

  if (isShowLoader) {
    return (
      <div className="loader">
        <BallTriangle
          height="100"
          width="100"
          color="grey"
          ariaLabel="loading"
        />
      </div>
    );
  }

  return (
    <>
      <Helmet>
        <title>Добавить</title>
      </Helmet>
      <Box sx={{ pt: 2 }}>
        <Container maxWidth={false}>
          <Button startIcon={<ChevronLeft />} onClick={() => navigate(-1)}>
            Back
          </Button>
        </Container>
      </Box>
      <Box
        sx={{ backgroundColor: 'background.default', minHeight: '100%', pt: 3 }}
      >
        <Container maxWidth={false}>
          <Box sx={{ mb: 1 }}>
            <Alert
              severity={alert.type}
              style={{ display: alert.isVisible ? 'flex' : 'none' }}
            >
              {alert.txt}
            </Alert>
          </Box>
          <form>
            <Card>
              <CardHeader title="Редактирование фото" />
              <Divider />
              <CardContent sx={{ position: 'relative' }}>
                <div className="itemWrapper">
                  <div className="container">
                    <input
                      accept="xlsx/*"
                      type="file"
                      style={{ display: 'none' }}
                      id={1}
                      onChange={(event) => {
                        setSubmitDisabled(true);
                        avaUploaded(event, 1);
                      }}
                    />
                    <label htmlFor={1}>
                      <img src={uploadedImg} className="itemImg" />
                      <div className="middle" />
                    </label>
                  </div>
                  <div className="help-text">
                    Доступны следующие расширения: .png .jpg .svg .bmp .tga
                    .webp
                  </div>
                </div>
              </CardContent>
              <Divider />
              <Box sx={{ display: 'flex', justifyContent: 'flex-end', p: 2 }}>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={uploadPhoto}
                >
                  Сохранить
                </Button>
              </Box>
            </Card>
          </form>
        </Container>

        <Container maxWidth={false}>
          <Box sx={{ pt: 2 }}>
            <form>
              <Card>
                <CardHeader title="Добавление" />
                <Divider />
                <CardContent sx={{ position: 'relative' }}>
                  <TextField
                    fullWidth
                    label="Имя"
                    margin="normal"
                    name="name"
                    onChange={handleChange}
                    type="text"
                    value={values.name}
                    variant="outlined"
                    error={errors.name}
                  />
                  <TextField
                    fullWidth
                    label="Должность"
                    margin="normal"
                    name="title"
                    onChange={handleChange}
                    type="text"
                    value={values.title}
                    variant="outlined"
                    error={errors.title}
                  />
                  <TextField
                    fullWidth
                    label="Email"
                    margin="normal"
                    name="email"
                    onChange={handleChangeEmail}
                    type="text"
                    value={values.email}
                    variant="outlined"
                    error={errors.email}
                  />

                  <Alert
                    severity={alert.type}
                    style={{ display: alert.isVisible ? 'flex' : 'none' }}
                  >
                    {alert.txt}
                  </Alert>
                </CardContent>
                <Divider />
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', p: 2 }}>
                  <Button
                    disabled={submitDisabled}
                    color="primary"
                    variant="contained"
                    onClick={submit}
                  >
                    Добавить
                  </Button>
                </Box>
              </Card>
            </form>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default AdministrationAdd;
