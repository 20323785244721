import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import {
  Box,
  Container,
  Button,
  Card,
  CardHeader,
  Divider,
  CardContent,
  CircularProgress,
  TextField,
  Alert,
  Grid
} from '@material-ui/core';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import { useParams, useNavigate } from 'react-router-dom';
import { useGet, usePost, usePut } from '../../API/request';
import { BallTriangle } from 'react-loader-spinner';

import '../../styles/Avatar/style.css';

const BannerEdit = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const getOne = useGet();

  const postLogo = usePost();
  const putBanner = usePut();
  const [isLoaded, setIsLoaded] = useState(true);
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const [nothingChanged, setNothingChanged] = useState(true);

  const [uploadedImg, setUploadedImg] = useState('/static/images/defphoto.jpg');
  const [values, setValues] = useState({
    heading: '',
    description: '',
    link: '',
    photo: ''
  });
  const [errors, setErrors] = useState({
    heading: false,
    description: false,
    photo: false,
    link: false
  });

  const [alert, setAlert] = useState({
    txt: '',
    isVisible: false,
    type: 'error'
  });

  const handleChange = (event) => {
    setNothingChanged(false);
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
    setErrors({
      ...errors,
      [event.target.name]: false
    });
  };

  const avaUploaded = (event) => {
    setNothingChanged(false);
    setUploadedImg(URL.createObjectURL(event.target.files[0]));
    setValues({
      ...values,
      photo: event.target.files[0]
    });
  };

  const showAlert = (type, text) => {
    setAlert({
      txt: text,
      type,
      isVisible: true
    });

    setTimeout(() => {
      setAlert({
        txt: text,
        type,
        isVisible: false
      });

      setSubmitDisabled(false);
    }, 2500);
  };

  const validateInfo = () => {
    let validComplete = true;
    let formErrors = { ...errors };

    if (values.heading === '') {
      validComplete = false;
      formErrors.heading = false;
      showAlert('error', 'Заголовок не должен быть пустым');
    }

    if (values.description === '') {
      validComplete = false;
      formErrors.description = false;
      showAlert('error', 'Описание не должно быть пустым');
    }

    if (values.link === '') {
      validComplete = false;
      formErrors.link = false;
      showAlert('error', 'Поле Ссылка не должно быть пустым');
    }

    setErrors(formErrors);
    return validComplete;
  };

  const uploadLogo = async () => {
    if (nothingChanged) {
      showAlert('error', 'Нет изменений');
      return;
    }

    setSubmitDisabled(true);

    let data = new FormData();
    data.append('photo', values.photo);
    data.append('fields', values);

    postLogo(`banners/${id}/photo`, data)
      .then((resp) => {
        if (resp.status === 'success') {
          showAlert('success', 'Данные успешно обновленны');
        } else {
          showAlert('error', 'Ошибка');
        }
      })
      .catch((err) => {
        showAlert('error', 'Ошибка сервера');
      })
      .finally(() => {
        setSubmitDisabled(false);
      });
  };

  const submitInfo = async () => {
    if (nothingChanged) {
      showAlert('error', 'Нет изменений');
      return;
    }

    if (validateInfo()) {
      setSubmitDisabled(true);
      let data = {
        photo: uploadedImg,
        heading: values.heading,
        description: values.description,
        link: values.link
      };

      putBanner(`banners/${id}`, data)
        .then((resp) => {
          if (resp.status === 'success') {
            showAlert('success', 'Данные успешно обновленны');
          } else {
            showAlert('error', 'Ошибка');
          }
        })
        .catch((err) => {
          showAlert('error', 'Ошибка сервера');
        })
        .finally(() => {
          setSubmitDisabled(false);
        });
    }
  };

  useEffect(() => {
    getOne(`banners/${id}`)
      .then((resp) => {
        if (resp.status === 'success') {
          console.log(resp);
          const data = {
            photo: resp.data.banner.photo,
            heading: resp.data.banner.heading,
            description: resp.data.banner.description,
            link: resp.data.banner.link
          };
          setValues(data);
          const photo = resp.data.banner.photo
            ? `${process.env.REACT_APP_API_URL}/uploads/logos/${resp.data.banner.photo}`
            : '';

          setUploadedImg(photo);
        }
      })
      .catch(() => {
        showAlert(
          'error',
          'Произошла ошибка при загрузке, попробуйте перезайти'
        );
      })
      .finally(() => {});

    setIsLoaded(true);
  }, []);

  if (!isLoaded) {
    return (
      <div className="loader">
        <BallTriangle
          height="100"
          width="100"
          color="grey"
          ariaLabel="loading"
        />
      </div>
    );
  }

  return (
    <>
      <Helmet>
        <title>Редактировать баннер</title>
      </Helmet>
      <Box className="headerWrapper">
        <Box className="headerTitle">
          <Button startIcon={<ChevronLeft />} onClick={() => navigate(-1)}>
            Назад
          </Button>
          Баннеры
        </Box>
        <ul className="headerList">
          <li onClick={() => navigate(-1)}>Баннеры</li>
          <li>/</li>
          <li>Редактирование</li>
        </ul>
      </Box>

      {/*image*/}
      <Box sx={{ backgroundColor: 'background.default', pt: 3 }}>
        <Container maxWidth={false}>
          <Box sx={{ mb: 1 }}>
            <Alert
              severity={alert.type}
              style={{ display: alert.isVisible ? 'flex' : 'none' }}
            >
              {alert.txt}
            </Alert>
          </Box>
          <Box
            sx={{
              paddingBottom: 1
            }}
          >
            <form>
              <Card>
                <CardHeader title="Редактирование фото" />
                <Divider />
                <CardContent sx={{ position: 'relative' }}>
                  <div className="itemWrapper">
                    <div className="container">
                      <input
                        accept=".png, .jpg, .svg, .bmp, .tga, .webp"
                        type="file"
                        style={{ display: 'none' }}
                        id={1}
                        onChange={(event) => avaUploaded(event, 1)}
                      />
                      <label htmlFor={1}>
                        <img src={uploadedImg} className="itemImg" />
                        <div className="middle" />
                      </label>
                    </div>
                    <div className="help-text">
                      Доступны следующие расширения: .png .jpg .svg .bmp .tga
                      .webp
                    </div>
                  </div>
                </CardContent>
                <Divider />
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', p: 2 }}>
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={uploadLogo}
                    disabled={submitDisabled}
                  >
                    Сохранить
                  </Button>
                </Box>
              </Card>
            </form>
          </Box>
        </Container>

        {/*info*/}
        <Card>
          <CardHeader title="Редактирование баннера" />
          <Divider />
          <CardContent sx={{ position: 'relative' }}>
            <TextField
              fullWidth
              label="Заголовок"
              margin="normal"
              name="heading"
              onChange={handleChange}
              type="text"
              value={values.heading}
              variant="outlined"
              error={errors.heading}
            />
            <TextField
              fullWidth
              label="Описание"
              margin="normal"
              name="description"
              onChange={handleChange}
              type="text"
              value={values.description}
              variant="outlined"
              error={errors.description}
            />
            <TextField
              fullWidth
              label="Ссылка"
              margin="normal"
              name="link"
              onChange={handleChange}
              type="link"
              value={values.link}
              variant="outlined"
              error={errors.link}
            />

            <Alert
              severity={alert.type}
              style={{ display: alert.isVisible ? 'flex' : 'none' }}
            >
              {alert.txt}
            </Alert>
          </CardContent>
          <Divider />
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', p: 2 }}>
            <Button
              color="primary"
              variant="contained"
              onClick={submitInfo}
              disabled={submitDisabled}
            >
              Сохранить
            </Button>
          </Box>
        </Card>
      </Box>
    </>
  );
};

export default BannerEdit;
