import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import {
  Box,
  Avatar,
  Container,
  Button,
  Card,
  CardContent
} from '@material-ui/core';
import ChevronLeft from '@material-ui/icons/ChevronLeft';

import { Link as RouterLink, useParams, useNavigate } from 'react-router-dom';
import { useGet } from '../../API/request';
import '../../styles/All.css';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { BallTriangle } from 'react-loader-spinner';

const NewsAndOffersInfo = () => {
  const navigate = useNavigate();
  const getNewsAndOffers = useGet();
  const { id } = useParams();

  const [isLoaded, setIsLoaded] = useState(true);

  const [newsAndOffers, setNewsAndOffers] = useState({});

  useEffect(() => {
    setIsLoaded(true);

    getNewsAndOffers(`news-and-offers/${id}`)
      .then((resp) => {
        if (resp.status === 'success') {
          setNewsAndOffers(resp.data.news);
        }
      })
      .catch(() => {})
      .finally(() => {
        setIsLoaded(false);
      });
  }, []);

  
  if (isLoaded) {
    return (
      <div className="loader">
        <BallTriangle
          height="100"
          width="100"
          color="grey"
          ariaLabel="loading"
        />
      </div>
    );
  }

  return (
    <>
      <Helmet>
        <title>Новости и предложения</title>
      </Helmet>
      <Box className="headerWrapper">
        <Box className="headerTitle">
          <Button startIcon={<ChevronLeft />} onClick={() => navigate(-1)}>
            Назад
          </Button>
          Новости и предложения
        </Box>
        <ul className="headerList">
          <li onClick={() => navigate(-1)}>Новости и предложения</li>
          <li>/</li>
          <li>Информация</li>
        </ul>
      </Box>

      <Box sx={{ backgroundColor: 'background.default', pt: 3, pb: 1 }}>
        <Container maxWidth={false}>
          <Card>
            <CardContent sx={{ p: 3 }}>
              <PerfectScrollbar>
                <div>
                  <div>
                   
                     <div className="wrapAvatar">
                    <div className="avatar-block">
                           <Avatar
                      className='avatar'
                        src={
                          newsAndOffers?.photo.startsWith('http')
                            ? newsAndOffers?.photo
                            : `${process.env.REACT_APP_API_URL}/uploads/logos/${newsAndOffers?.photo}`
                        }
                        alt={newsAndOffers?.title}
                      />
                    </div>
                   
                 
                    

                    <div className="info-block">
                      <div className="wrap">
                        <div className="label">ID:</div>
                        <div className="text">{newsAndOffers?.id || '---'}</div>
                      </div>
                      <div className="wrap">
                        <div className="label">Заголовок:</div>
                        <div className="text">
                          {newsAndOffers?.title || '---'}
                        </div>
                      </div>

                      <div className="wrap">
                        <div className="label">Опублик.:</div>
                        <div className="text">
                          {newsAndOffers?.publishedAt
                            ? new Date(newsAndOffers.publishedAt).toLocaleDateString()
                            : 'Не опубликовано'}
                        </div>
                      </div>
                      <div className="wrap">
                        <div className="label">Дата создания:</div>
                        <div className="text">
                          {newsAndOffers?.createdAt && new Date(newsAndOffers.createdAt).toLocaleDateString() || '---'}
                        </div>
                      </div>

                      <div className="wrap">
                        <div className="label">Категория:</div>
                        <div className="text">
                          {newsAndOffers?.news_and_offers_category_name ||
                            '---'}
                        </div>
                      </div>
                    </div>
                     </div>
                  </div>
                </div>
              </PerfectScrollbar>
            </CardContent>
          </Card>
          <Card>
            <CardContent>
              <Box className="headerTitle">Содержание:</Box>
              <Box>
                <div
                  dangerouslySetInnerHTML={{
                    __html: newsAndOffers?.description
                  }}
                />
              </Box>
            </CardContent>
          </Card>
        </Container>
      </Box>
    </>
  );
};

export default NewsAndOffersInfo;
