import { Helmet } from 'react-helmet';
import {
  Box,
  Container,
  Button,
  TextField,
  CardContent
} from '@material-ui/core';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import { useNavigate } from 'react-router-dom';
import { useGet, usePost, usePut } from '../../API/request';
import React, { useEffect, useState } from 'react';
import { BallTriangle } from 'react-loader-spinner';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import Divider from '@material-ui/core/Divider';
import Alert from '@material-ui/core/Alert';

const FoodAdd = () => {
  const navigate = useNavigate();
  const postFood = usePost();
  const postLogo = usePost();
  const getCategories = useGet();

  const [isLoaded, setIsLoaded] = useState(true);

  const [uploadedImg, setUploadedImg] = useState('/static/images/defphoto.jpg');
  const [values, setValues] = useState({
    name: '',
    description: '',
    working_hours: '',
    website: '',
    social_media_link: '',
    food_category_id: ''
  });
  const [errors, setErrors] = useState({
    name: false,
    description: false,
    working_hours: false,
    website: false,
    social_media_link: false,
    food_category_id: false
  });
  const [categories, setCategories] = useState([]);

  const [isShowLoader, setIsShowLoader] = useState(false);
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const [logoUrl, setLogoUrl] = useState('');

  const [alert, setAlert] = useState({
    txt: '',
    isVisible: false,
    type: 'error'
  });

  const avaUploaded = (event) => {
    setUploadedImg(URL.createObjectURL(event.target.files[0]));
    setValues({
      ...values,
      logo: event.target.files[0]
    });
  };

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
    setErrors({
      ...errors,
      [event.target.name]: false
    });
  };

  const showAlert = (type, text) => {
    setAlert({
      txt: text,
      type,
      isVisible: true
    });

    setTimeout(() => {
      setAlert({
        txt: text,
        type,
        isVisible: false
      });

      setSubmitDisabled(false);
    }, 1400);
  };

  const validate = () => {
    let validComplete = true;
    let formErrors = { ...errors };

    if (values.name === '') {
      validComplete = false;
      formErrors.name = false;
      showAlert('error', 'Название не должно быть пустым');
    }

    if (values.description === '') {
      validComplete = false;
      formErrors.description = false;
      showAlert('error', 'Описание не должно быть пустым');
    }

    if (values.working_hours === '') {
      validComplete = false;
      formErrors.working_hours = false;
      showAlert('error', 'Поле Рабочие Часы не должно быть пустым');
    }

    if (values.website === '') {
      validComplete = false;
      formErrors.website = false;
      showAlert('error', 'Поле Веб-сайт не должно быть пустым');
    }
    if (values.social_media_link === '') {
      validComplete = false;
      formErrors.social_media_link = false;
      showAlert('error', 'Поле Соц.сети не должно быть пустым');
    }
    if (values.category_id === '') {
      validComplete = false;
      formErrors.category_id = false;
      showAlert('error', 'Поле Категория не должно быть пустым');
    }

    setErrors(formErrors);
    return validComplete;
  };

  const clearForm = () => {
    setValues({
      name: '',
      description: '',
      working_hours: '',
      website: '',
      social_media_link: '',
      category_id: ''
    });
  };

  const uploadLogo = async () => {
    let data = new FormData();
    data.append('logo', values.logo);

    postLogo(`food/logo/new`, data)
      .then((resp) => {
        if (resp.status === 'success') {
          showAlert('success', 'Данные успешно обновленны');
          setLogoUrl(resp.data.file);
        } else {
          showAlert('error', 'Ошибка');
        }
      })
      .catch((err) => {
        showAlert('error', 'Ошибка сервера');
      })
      .finally(() => {
        setSubmitDisabled(false);
      });
  };

  const submit = async () => {
    if (validate()) {
      setIsShowLoader(true);
      setSubmitDisabled(true);

      let data = {
        logo: logoUrl,
        name: values.name,
        description: values.description,
        working_hours: values.working_hours,
        website: values.website,
        food_category_id: values.category_id,
        social_media_link: values.social_media_link
      };

      postFood('food', data)
        .then((resp) => {
          if (resp.status === 'success') {
            showAlert('success', 'Магазин добавлен');
            clearForm();
          } else {
            showAlert('error', 'Ошибка');
          }
        })
        .catch((err) => {
          showAlert('error', 'Ошибка сервера');
          setIsShowLoader(false);
          setSubmitDisabled(false);
        })
        .finally(() => {});
    }
  };
  useEffect(() => {
    setIsLoaded(true);
    getCategories('categories/food')
      .then((resp) => {
        if (resp.status === 'success') {
          setCategories(resp.data.categories.data);
          setValues({ ...values, category_id: resp.data.categories.data[1] });
        }
      })
      .catch(() => {
        showAlert(
          'error',
          'Произошла ошибка при загрузке категорий, попробуйте перезайти'
        );
      })
      .finally(() => {
        setIsLoaded(false);
      });
  }, []);

  if (isLoaded) {
    return (
      <div className="loader">
        <BallTriangle
          height="100"
          width="100"
          color="grey"
          ariaLabel="loading"
        />
      </div>
    );
  }

  return (
    <>
      <Helmet>
        <title>Добавить ресторан</title>
      </Helmet>
      <Box sx={{ pt: 2 }}>
        <Container maxWidth={false}>
          <Button startIcon={<ChevronLeft />} onClick={() => navigate(-1)}>
            Back
          </Button>
        </Container>
      </Box>
      <Box
        sx={{ backgroundColor: 'background.default', minHeight: '100%', pt: 3 }}
      >
        <Container maxWidth={false}>
          <Box sx={{ mb: 1 }}>
            <Alert
              severity={alert.type}
              style={{ display: alert.isVisible ? 'flex' : 'none' }}
            >
              {alert.txt}
            </Alert>
          </Box>
          <form>
            <Card>
              <CardHeader title="Редактирование логотипа" />
              <Divider />
              <CardContent sx={{ position: 'relative' }}>
                <div className="itemWrapper">
                  <div className="container">
                    <input
                      accept="xlsx/*"
                      type="file"
                      style={{ display: 'none' }}
                      id={1}
                      onChange={(event) => {
                        setSubmitDisabled(true);
                        avaUploaded(event, 1);
                      }}
                    />
                    <label htmlFor={1}>
                      <img src={uploadedImg} className="itemImg" />
                      <div className="middle" />
                    </label>
                  </div>
                  <div className="help-text">
                    Доступны следующие расширения: .png .jpg .svg .bmp .tga
                    .webp
                  </div>
                </div>
              </CardContent>
              <Divider />
              <Box sx={{ display: 'flex', justifyContent: 'flex-end', p: 2 }}>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={uploadLogo}
                >
                  Сохранить
                </Button>
              </Box>
            </Card>
          </form>
        </Container>

        <Container maxWidth={false}>
          <Box sx={{ pt: 2 }}>
            <form>
              <Card>
                <CardHeader title="Добавление нового ресторана" />
                <Divider />
                <CardContent sx={{ position: 'relative' }}>
                  <TextField
                    fullWidth
                    label="Название"
                    margin="normal"
                    name="name"
                    onChange={handleChange}
                    type="text"
                    value={values.name}
                    variant="outlined"
                    error={errors.name}
                  />
                  <TextField
                    fullWidth
                    label="Описание"
                    margin="normal"
                    name="description"
                    onChange={handleChange}
                    type="text"
                    value={values.description}
                    variant="outlined"
                    error={errors.description}
                  />
                  <TextField
                    fullWidth
                    label="Рабочее время"
                    margin="normal"
                    name="working_hours"
                    onChange={handleChange}
                    type="working_hours"
                    value={values.working_hours}
                    variant="outlined"
                    error={errors.working_hours}
                  />
                  <TextField
                    fullWidth
                    label="Веб-сайт"
                    margin="normal"
                    name="website"
                    onChange={handleChange}
                    value={values.website}
                    variant="outlined"
                    error={errors.website}
                  />
                  <TextField
                    fullWidth
                    label="Соц.сеть"
                    margin="normal"
                    name="social_media_link"
                    onChange={handleChange}
                    value={values.social_media_link}
                    variant="outlined"
                    error={errors.social_media_link}
                  />
                  <FormControl fullWidth sx={{ mt: 2, mb: 1 }}>
                    <InputLabel id="category">Категория</InputLabel>
                    <Select
                      labelId="category"
                      name="category_id"
                      value={values.category_id}
                      label="category"
                      onChange={handleChange}
                    >
                      {categories?.map((item) => (
                        <MenuItem
                          key={item.category_id}
                          value={item.category_id}
                        >
                          {item.category_name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                  <Alert
                    severity={alert.type}
                    style={{ display: alert.isVisible ? 'flex' : 'none' }}
                  >
                    {alert.txt}
                  </Alert>
                </CardContent>
                <Divider />
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', p: 2 }}>
                  <Button
                    disabled={submitDisabled}
                    color="primary"
                    variant="contained"
                    onClick={submit}
                  >
                    Добавить
                  </Button>
                </Box>
              </Card>
            </form>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default FoodAdd;
